
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import { getBlogs, ParsedItem } from '../lib/Contentful'

interface TeamData {
  blogs: ParsedItem[];
}

export default defineComponent({
  name: 'Blogs',
  components: {
    PageHeader
  },
  data(): TeamData {
    return {
      blogs: []
    }
  },
  async mounted() {
    await this.getBlogs();
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getBlogs() {
      this.blogs = await getBlogs()
    }
  }
});
